<template>
  <div>
    <p class="activation-code">激活码：{{ code }}</p>
    <Upload
        multiple
        type="drag"
        action='test'
        :before-upload="selectFile"
        :default-file-list="fileList"
        :disabled="isUploading"
    >
      <div style="padding: 20px 0;text-align:center">
        <Icon v-if="!isUploading" type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
        <p v-if="!isUploading">点击或拖动上传文件</p>
        <Spin v-if="isUploading" size="large"></Spin>
      </div>
    </Upload>
    <p class="step-btn">
      <Button @click="last">上一步</Button>
      <Button @click="next">下一步</Button>
    </p>
  </div>
</template>

<script>
export default {
  name: "UploadRequest",
  data() {
    return {
      fileList: [],
      isUploading: false,
      fileData: ""
    }
  },
  props: ["code"],
  methods: {
    last(){
      this.$router.push({path:"info",query:{code:this.$route.query.code}});
    },
    selectFile(file) {
      console.log(file);
      this.isUploading = true
      let reader = new FileReader()
      reader.onload = () => {
        this.fileData = btoa(reader.result);
        this.isUploading = false;
      }
      reader.readAsBinaryString(file)
      this.fileList = [{
        name: file.name
      }]
      return false;
    },
    next() {
      this.isUploading = true;
      // console.log(new Blob(reader.result))
      this.$http.post('/api/Activation/IssueLicenseAsync', {
        code: this.code,
        request: this.fileData
      }).then((data) => {
        if (data.data.status ) {
          this.$Message.success('授权成功！');
          this.$router.push({name: "Success", params: {file: data.data.rel}});
        } else {
          this.$Message.error('激活请求文件无效！'+data.data.message);
          this.isUploading = false;
        }
      }).catch((e) => {
        this.$Message.error('网络错误');
        console.error(e);
        this.isUploading = false;
      })
    }
  }
}
</script>

<style>
.activation-code {
  text-align: center;
  margin: 10px 0px;
}
</style>
