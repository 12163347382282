<template>
    <div class="about">
        <!-- <StepsLine :current="1"/> -->
        <Form ref="formInline" :model="formItem" :rules="rule">
            <FormItem prop="input" class="code-form">
                <Input v-model="formItem.input" class="code-form-input" placeholder="授权码"/>
            </FormItem>
            <FormItem style="text-align:right">
                <Button type="primary" @click="handleSubmit('formInline')" :maxlength="13">验证</Button>
            </FormItem>
        </Form>
    </div>
</template>

<script>

export default {
    data () {
        return {
            formItem: {
                input: '',
            },
            rule:{
                input:[{
                    trigger: 'blur',
                    validator: (rule, value, callback)=>{
                        if (!value) {
                            return callback(new Error('请输入授权码'));
                        }
                        if(/^[KBNTMPLWZ][QAZXSWEDCVFR][MKLZWER]-[A-z0-9^IO01]{5}-[A-z0-9]{3}$/.test(value)){
                            return callback()
                        }else{
                            return callback(new Error('无效的授权码！'));
                        }
                    }
                }]
            }
        }
    },
    methods: {
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$http.get("/api/Activation/VerifyCodeAsync",{params:{code:this.formItem.input}}).then((response) => {
                        console.log()
                        if(JSON.parse(response.data)){
                            // this.$router.push({path:"upload-request",query:{code:this.formItem.input}});
                            this.$router.push({path:"info",query:{code:this.formItem.input}});
                            this.$Message.success('Success!');
                        }else{
                            this.$Message.error('授权码无效，请联系售后人员！')
                        }
                    })
                    
                } else {
                    this.$Message.error('授权码验证失败!');
                }
            })
        }
    }
}
</script>

<style>
.code-form {
  max-width: 450px;
  text-align: center;
  margin: 20px auto;
}

.code-form-input input {
  text-align: center;
}
</style>
