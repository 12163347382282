<template>
    <div>
        <br/>
        <p class=center>系统自动下载了激活响应文件，请将激活响应文件导入系统。如果没有开始下载，请点<a ref="download" download="ActivationResponse.bin" :href="`data:application/octet-stream;base64,${$route.params.file}`">这里</a>下载。</p>
    </div>
</template>

<script>

export default {
    name:'Success',
    props:["file"],
    mounted(){
        this.$refs.download.click();
    }
}
</script>

<style>

</style>
