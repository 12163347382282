<template>
  <div>
    <!-- <StepsLine :current="2"/> -->
    <div class="center">
      <CellGroup>
        <Cell title="许可证ID" :label="info.id"/>
        <Cell title="授权人" :label="info.commonName"/>
        <Cell v-for="(item,i) in info.licenseInfoAttributes" :key="i" :title="item.key" :label="item.value"/>
      </CellGroup>
      <p class="step-btn">
        <Button @click="last">上一步</Button>
        <Button @click="next">下一步</Button>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  props: ['code'],
  data() {
    return {
      info: {
        licenseInfoAttributes: []
      }
    }
  },

  mounted() {
    console.log(this)
    // console.log(this.code)
    this.$http.get('/api/Activation/GetLicenseInfoAsync', {params: {code: this.$route.query.code}}).then((data) => {
      this.info = data.data;
    })
  },
  methods: {
    next() {
      this.$router.push({path: "upload-request", query: {code: this.$route.query.code}});
    },
    last() {
      this.$router.push({path: "activation-code", query: {code: this.$route.query.code}});
    }
  }
}
</script>

<style>

</style>
