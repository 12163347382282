<template>
    <div class="home">
        <div class="center">
            <Button type="primary" @click="next">开始</Button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Home',
    methods:{
        next(){
            this.$router.push({path:"/activation-code"});
        }
    }
}
</script>

<style>
</style>
