import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Success from '../views/Success.vue'
import Info from '../views/Info.vue';
import UploadRequest from '../views/UploadRequest';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      current:0
    }
  },
  {
    path: '/activation-code',
    name: 'ActivationCode',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ActivationCode.vue'),
    meta: {
      current:1
    }
  },
  {
    path:'/info',
    name:'Info',
    component:Info,
    meta: {
      current:2
    }
  },
  {
    path:'/upload-request/',
    name:"UploadRequest",
    component:UploadRequest,
    props:route => ({ code: route.query.code }),
    meta: {
      current:3
    }
  },
  {
    path: '/success',
    name: 'Success',
    component: Success,
    meta: {
      current:4
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
