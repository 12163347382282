<template>
  <div id="app">
    <p class="no-padding"><img :src="logo" width="100"></p>
    <h1>数字授权离线激活</h1>
    <Card style="width:800px;margin:auto">
      <p slot="title">离线授权</p>
      <a href="#" slot="extra" @click="back">返回</a>
      <StepsLine :current="step"/>
      <br/>
      <router-view></router-view>
    </Card>
    <p>
      Copyright © 2016-{{ year }} <a href="https://www.zhonghui.vip/" target="_blank">中慧云启科技集团</a> All Rights Reserved.
      <br/>
      <a href="http://beian.miit.gov.cn" target="_blank" rel="nofollow">沪ICP备18041838号-2</a>
    </p>
  </div>
</template>

<script>
import StepsLine from './components/StepsLine';
import logo from './assets/zhonghui_group_2.svg'

export default {
  name: 'app',
  components: {
    StepsLine
  },
  data() {
    return {
      step: 0,
      logo,
    }
  },
  computed: {
    year() {
      return new Date().getFullYear();
    }
  },
  beforeUpdate() {
    console.log(this.step = this.$route.matched[0].meta.current)
    this.step = this.$route.matched[0].meta.current
  },
  methods: {
    back() {
      this.$router.push("/")
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50; */
  margin-top: 60px;
}

.center {
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.step-btn {
  text-align: right;
}

.step-btn button {
  margin: 10px 5px;
}

.step-btn button:last-child {
  margin-right: 0px;
}
</style>

<style scoped>
h1, p {
  text-align: center;
  padding: 25px 0px;
}

.no-padding {
  padding: 0px;
}

</style>
