<template>
    <Steps :current="current">
        <Step title="开始" content="开始"></Step>
        <Step title="填写授权码" content="填写授权码"></Step>
        <Step title="确认授权信息" content="显示授权信息"></Step>
        <Step title="上传激活请求" content="上传激活请求文件"></Step>
        <Step title="完成激活" content="下载激活响应文件"></Step>
    </Steps>
</template>

<script>
export default {
  name: 'StepsLine',
  props: {
    current: Number
  }
}
</script>

<style scoped>

</style>
