import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ViewUI from 'view-design';
import './plugins/iview.js'

document.title="数字授权离线激活 | 中慧云启科技集团"

Vue.config.productionTip = false
axios.defaults.baseURL="https://guard-api.wishtech.net/";
Vue.use(ViewUI);
Vue.use(VueAxios, axios)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
